import React from "react";
import Topbar from "../components/topbar";
import Navbar from "../components/navbar2";
import Container from "react-bootstrap/esm/Container";
import Footer from "../components/footer";
import { Col, Row } from "react-bootstrap";


function Navigation1() {
    return (
    <>
        <div>
            <Topbar />
            <Navbar />
            <section className="offer">
            <div className="offer-background"></div>
            <div className="offer-overlay"></div>
            <Container className="navContainer">
            <div className="offer-content">
                <h2 className="oswald white outline">CHCNAV</h2>
                <h1 className="oswaldBold white">NX512 SE</h1>
                <div>   
                <a href="https://chcharvester.olx.pl/home/q-FJDynamics/#848355793">
                    <button class="btn orange">OFERTY OLX</button>
                </a>
                <a href="http://chcharvester.pl/kontakt">
                    <button class="btn white">SKONTAKTUJ SIĘ</button>
                </a>
                </div>
            </div>
            <div className="navImageDiv">
            <img className="navImage" src={"../images/CHCSet.png"} alt={"CHC NX510 navigation"}/>
            </div>
            </Container>
        </section>
                <Container>
                     <img className="navImage2" src={"../images/CHCSet.png"} alt={"CHC NX510 navigation"}/>
                    <h1 className="center oswald navHeader">NX510 SE</h1>
                    <p className="paragraph">NX510 SE to zautomatyzowany system kierowania, który łatwo dostosowuje się do wielu rodzajów nowych i starych traktorów rolniczych oraz innych pojazdów, zapewniając kompaktowe, aktualne i kompleksowe rozwiązanie, które każde gospodarstwo może sobie pozwolić. Zapewnia znaczne wzrosty produktywności, działa we wszystkich warunkach widoczności i zmniejsza zmęczenie operatora.</p>
                    <p className="paragraph">NX510 SE wykorzystuje najnowszą generację zintegrowanego kontrolera nawigacji, który jest łatwiejszy w instalacji, aby nie marnować cennego czasu na polu. Jego ulepszone moduły wbudowane zapewniają łączność, w tym modem 4G i dodatkowy modem radiowy UHF, umożliwiając pracę z ulubionymi źródłami korekcji RTK z lokalnych sieci RTK lub bazowych stacji RTK GNSS. Ponadto, może być łatwo przenoszony z jednego traktora na drugi, oferując niezrównaną elastyczność. Kontroler nawigacji, który obsługuje pełne konstelacje GNSS, oferuje zwiększoną dokładność dla optymalnej niezawodności nawigacji.</p>
                    <p className="paragraph">Zintegrowana technologia kompensacji terenu GNSS+INS zapewnia dokładność prowadzenia bez użycia rąk z dokładnością do centymetra (lub mniej niż cala) i zapewnia doskonałe rezultaty w aplikacjach związanych z kopaniem rowów, sadzeniem i żniwami.</p>
                </Container>
                <Container>
                    <h1 className="center oswald navHeader">NAJWAŻNIEJSZE CECHY</h1>
                    <ul className="paragraph">
                        <li>Wielojęzyczne oprogramowanie AgNav</li>
                        <li>10,1 calowy wyświetlacz</li>
                        <li>Obsługa wielu wzorów nawigacji, w tym linię AB, linię A+, linię okrężną, nieregularną krzywą i zawracanie</li>
                        <li>Kierownica z bezszczotkowym silnikiem zapewniającym cichą i komfortową pracę</li>
                        <li>Czujnik kąta skrętu bez ruchomych elementów gwarantuje precyzyjną pracę nawet w ektremalnych warunkach</li>
                        <li>Antena wykorzystująca satelity GPS, GLONASS, Galileo, BDS, QZSS, SBAS</li>
                        <li>Kamera umożliwi Ci podgląd narzędzi podczas pracy</li>
                        <li>Dokładność do 2,5 cm</li>
                        <li>Tworzenie i zapisywanie pól, linii prowadzących</li>
                        <li>Pomiary pól, tworzenie zleceń, zadań, gospodarstw i wiele innych</li>
                        <li>ISOBUS</li>
                        <li>Section Control</li>
                        <li>Pyłoodporne i wodoodporne komponenty świetnie sprawdzające się w każdym gospodarstwie</li>
                    </ul>
                </Container>
                <Container>
                <h2 className="center oswald navHeader">GWARANTUJEMY PEŁNE WSPARCIE TECHNICZNE: MONTAŻ, SZKOLENIE Z OBSŁUGI, AKTUALIZACJĘ, DORADZTWO TELEFONICZNE A TAKŻE SZYBKI SERWIS.</h2>
                <h2 className="center oswald navHeader">MONTAŻ BEZ INGERENCJI W MECHANIKĘ, HYDRAULIKĘ CIĄGNIKA BEZ UTRATY GWARANCJI.</h2>
                </Container>
                <Container className="navcol">
                <   Row>
                        <Col>
                        <a href="https://agriculture.chcnav.com/dam/jcr:bdb8246f-e066-470d-8c5d-d56d4ed73f30/NX510%20SE_DS_EN.pdf">
                        <div className="btn orange oswald navbtn">PEŁNA SPECYFIKACJA NAWIGACJI W JĘZYKU ANGIELSKIM</div>
                        </a>
                        </Col>
                    </Row>
                </Container> 
        </div>
             <Footer />
    </>
        
    )
}

export default Navigation1;