import React from "react";
import Topbar from "../components/topbar";
import Navbar from "../components/navbar2";
import Footer from "../components/footer";
import Container from "react-bootstrap/esm/Container";
import { Col, Row } from "react-bootstrap";
import Videos from "../components/videos";
import Seasons from "../components/seasons";

function About(){
    return (
    <div>
        <Topbar />
        <Navbar />
        <section className="hero">
            <div className="hero-background"></div>
            <div className="hero-overlay"></div>
            <Container>
            <div className="hero-content">
                <h1 className="oswaldBold white">HARVESTER Marcin Dyoniziak</h1>
                <h2 className="oswald white outline">Autoryzowany Polski dealer nawigacji rolniczej marki CHCNAV</h2>
                <div>   
                <a href="http://chcharvester.pl/oferta">
                    <button class="btn orange">NASZA OFERTA</button>
                </a>
                <a href="http://chcharvester.pl/kontakt">
                    <button class="btn white">SKONTAKTUJ SIĘ</button>
                </a>
                </div>
            </div>
            </Container>
        </section>
            <Container>
                <Seasons />
            </Container>
            <br /><br />
            <section className="video">
                <div className="video-background"></div>
                <div className="video-overlay"></div>
                <Container style={{ zIndex: "2"}}>
                    <Row>
                        <Col>
                            <Videos />
                        </Col>
                    </Row>
                </Container>
            </section>
            
        <Footer />
    </div>
       
    )
}

export default About;