import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Navigation() {
    return (
        <div className='navbarContainer'>
        <Container>
            <Row>
              {['lg'].map((expand) => (
                <Navbar key={expand} bg="" expand={expand}>
                    <Container >
                      <Col className='flex'>
                        {/* Logo po lewej */}
                        <Navbar.Brand href="/"><img className='harvester' src="/images/logo_harvesternewprecisioncultivation.png" alt="Harvester Logo" />
                        </Navbar.Brand>
                      </Col>
                       
                      <Col className='noflex'>
                            {/* Przycisk mobilnego menu */}
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

                          {/* Menu nawigacji */}
                          <Navbar.Offcanvas
                              id={`offcanvasNavbar-expand-${expand}`}
                              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                              placement="end"
                          >
                              <Offcanvas.Header closeButton>
                                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                        <img className='harvester' src="/images/logo_harvesternewprecisioncultivation.png" alt="Harvester Logo" />
                                  </Offcanvas.Title>
                              </Offcanvas.Header>
                              <Offcanvas.Body>

                                  {/* Przyciski po prawej */}
                                  <Nav className="justify-content-end flex-grow-1 pe-3">
                                      <Nav.Link href="/">HOME</Nav.Link>
                                      <Nav.Link href="/oferta">OFERTA NAWIGACJI</Nav.Link>
                                      <Nav.Link href="/kontakt">KONTAKT</Nav.Link>
                                  </Nav>
                              </Offcanvas.Body>
                          </Navbar.Offcanvas>
                      </Col>
                       
                    </Container>
                </Navbar>
            ))}   
            </Row>
           
        </Container>
            <hr className="divide"></hr>
        </div>
    );
}

export default Navigation;