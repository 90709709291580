import React from "react";
import Topbar from "../components/topbar";
import Navbar from "../components/navbar2";
import Container from "react-bootstrap/esm/Container";
import Footer from "../components/footer";
import { Col, Row } from "react-bootstrap";


function Navigation2() {
    return (
    <>
       <div>
            <Topbar />
            <Navbar />
            <section className="offer">
            <div className="offer-background"></div>
            <div className="offer-overlay"></div>
            <Container className="navContainer">
            <div className="offer-content">
                <h2 className="oswald white outline">CHCNAV</h2>
                <h1 className="oswaldBold white">NX612</h1>
                <div>   
                <a href="https://chcharvester.olx.pl/home/q-nx612/#971145568">
                    <button class="btn orange">OFERTY OLX</button>
                </a>
                <a href="http://chcharvester.pl/kontakt">
                    <button class="btn white">SKONTAKTUJ SIĘ</button>
                </a>
                </div>
            </div>
            <div className="navImageDiv">
            <img className="navImage" src={"../images/CHCnx612_3.png"} alt={"CHC NX510 navigation"}/>
            </div>
            </Container>
        </section>
                <Container>
                <img className="navImage2" src={"../images/CHCnx612_3.png"} alt={"CHC NX510 navigation"}/>
                    <h1 className="center oswald navHeader">NX612</h1>
                    <p className="paragraph">Najnowszy produkt marki CHCNAV czyli system automatycznego prowadzenia NX 612. Następna generacja zautomatyzowanego sterowania wyposażona w najnowszą technologie i rozwiązania techniczne. </p>
                    <p className="paragraph">System automatycznego sterowania NX612 zapewnia bezproblemową łączność z sieciami GNSS RTK oraz usługami korekcji satelitarnej CHCNAV. Wykorzystując technologię SkyTrix, osiąga dokładność ±2,5 cm nawet w odległych rejonach bez zasięgu sieci GNSS RTK, co czyni go idealnym rozwiązaniem dla rolnictwa precyzyjnego w dowolnym miejscu.</p>
                    <p className="paragraph">Kolejnym udoskonaleniem jest mniejszy, smuklejszy silnik elektryczny na kolumnę kierowniczą zwieńczony nową, trójramienną kierownicą z nowoczesnym designem i funkcją klaksonu w centralnej części.</p>
                    <p className="paragraph">Świetnie wpasuje się w wygląd każdego ciągnika na rynku, tak samo jak nowy tablet z 12-calowym wyświetlaczem o jeszcze wyższej rozdzielczości, większe ikony, widok w trybie 3D oraz jeszcze wydajniejszy procesor czynią ten zestaw bezkonkurencyjnym na rynku systemów automatycznego prowadzenia.</p>
                </Container>
                <Container>
                <h1 className="center oswald navHeader">NAJWAŻNIEJSZE CECHY</h1>
                <ul className="paragraph">
                    <li>Wielojęzyczne oprogramowanie AgNav</li>
                    <li>12 calowy wyświetlacz</li>
                    <li>Obsługa wielu wzorów nawigacji, w tym linię AB, linię A+, linię okrężną, nieregularną krzywą i zawracanie</li>
                    <li>Kierownica z bezszczotkowym silnikiem o nowym smuklejszym designie zapewniającym jeszcze wygodniejszą pracę w kabinie ciągnika</li>
                    <li>Czujnik kąta skrętu bez ruchomych elementów gwarantuje precyzyjną pracę nawet w ektremalnych warunkach</li>
                    <li>Antena wykorzystująca satelity GPS, GLONASS, Galileo, BDS, QZSS, SBAS</li>
                    <li>Kamera umożliwi Ci podgląd narzędzi podczas pracy</li>
                    <li>Dokładność ± 2,5 cm</li>
                    <li>Tworzenie i zapisywanie pól, linii prowadzących, podgląd map satelitarnych</li>
                    <li>Pomiary pól, tworzenie zleceń, zadań, gospodarstw i wiele innych</li>
                    <li>Dwukierunkowa transmisja danych umożliwiająca bezprzewodową komunikację danych z systemami zarządzania gospodarstwem i platformą FarmMaster</li>
                    <li>ISOBUS</li>
                    <li>Section Control</li>
                    <li>Pyłoodporne i wodoodporne komponenty świetnie sprawdzające się w każdym gospodarstwie</li>
                </ul>
                </Container>
                <Container>
                    <h2 className="center oswald navHeader">GWARANTUJEMY PEŁNE WSPARCIE TECHNICZNE: MONTAŻ, SZKOLENIE Z OBSŁUGI, AKTUALIZACJĘ, DORADZTWO TELEFONICZNE A TAKŻE SZYBKI SERWIS.</h2>
                    <h2 className="center oswald navHeader">MONTAŻ BEZ INGERENCJI W MECHANIKĘ, HYDRAULIKĘ CIĄGNIKA BEZ UTRATY GWARANCJI.</h2>
                </Container>
                <Container className="navcol">
                <   Row>
                        <Col>
                        <a href="https://agriculture.chcnav.com/dam/jcr:bc430733-28e3-4ffa-8ffd-d4f8a1d3f9fb/NX612_DS_EN.pdf">
                        <div className="btn orange oswald navbtn">PEŁNA SPECYFIKACJA NAWIGACJI W JĘZYKU ANGIELSKIM</div>
                        </a>
                        </Col>
                    </Row>
                </Container> 
        </div>
             <Footer />
    </>
        
    )
}

export default Navigation2;