import React from "react";
import Topbar from "../components/topbar";
import Navbar from "../components/navbar2";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { EnvelopeAt, House, InfoCircle, TelephoneFill } from "react-bootstrap-icons";
import Footer from "../components/footer";


function Kontakt() {
    return (
    <>
        <div>
            <Topbar />
            <Navbar />
            <div class="contact">
                <Container>
                    <Row>
                        <h2 className="center">Zapraszamy do kontaktu!</h2>
                        <hr className="divide" />
                        <Col>
                            <Row>
                                <Col md={6}>
                                    <div className="contact-content center">
                                        <h5><TelephoneFill />TELEFON:</h5>
                                        <p><br />
                                            <a className="contact" href="tel:+48 887 979 450">+48 887 979 450</a>
                                            <br />
                                            <a className="contact" href="tel:+48 662 756 446">+48 662 756 446</a>
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="contact-content center">
                                    <h5><EnvelopeAt />EMAIL:</h5>
                                        <p>
                                        <br />
                                            <a className="contact" href="mailto:biuroharvester@gmail.com">biuroharvester@gmail.com</a>
                                        <br />
                                            <a className="contact" href="mailto:harvester.dyoniziak@gmail.com">harvester.dyoniziak@gmail.com</a>
                                        <br />
                                            <a className="contact" href="mailto:harvesterzamowienia@gmail.com">harvesterzamowienia@gmail.com</a>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <hr className="divide"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="center">
                            <h5><House/>  ODWIEDŹ NAS W BIURZE!</h5>
                            <p>
                                <br />
                                Ul. Kolejowa 6
                                <br/>
                                63-308 Gizałki
                            </p>
                        </Col>
                        <Col md={6} className="center">
                                <h5><InfoCircle />  DANE ADRESOWE FIRMY</h5>
                                <p>
                                <br /> 
                                Harvester Marcin Dyoniziak<br />
                                Niniew 40<br />
                                63-313 Chocz<br />
                                NIP: 6172027301</p>
                        </Col>
                    </Row>
                    <hr className="divide" />
                </Container>
            </div>
        </div>
        <Container className="text-center">
             <Row>
                 <Col xs lg={12}>
                        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d795.572217291283!2d17.765777757907955!3d52.04550824840759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4704d78e03805329%3A0x67c4ff61606e8dc5!2sHarvester%20Marcin%20Dyoniziak!5e0!3m2!1spl!2spl!4v1702418004503!5m2!1spl!2spl" width="100%" height="600" style={{ border: 0, marginBottom: '10rem' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                 </Col>
            </Row>
        </Container>
        <Footer />
    </>
        
    )
}

export default Kontakt;