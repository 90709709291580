import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/about";
import Kontakt from "./pages/contact";
import NoPage from "./pages/noPage";
import Navoffer from "./pages/navoffer";
import Nx510 from "./pages/nx510";
import Nx612 from "./pages/nx612";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="oferta" element={<Navoffer />} />
        <Route path="oferta/nx510" element={<Nx510 />} />
        <Route path="oferta/nx612" element={<Nx612 />} />
        <Route path="kontakt" element={<Kontakt />} />
        <Route path="*" element={<NoPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
