import Card from 'react-bootstrap/Card';

function navcard(props) {
  return (
    <Card className='navcard' style={{ width: '21rem' }}>
      <Card.Img className='cardimg' variant="top" src={props.imgsrc} />
      <Card.Body>
        <Card.Title className='oswald' >{props.title}</Card.Title>
      </Card.Body>
    </Card>
  );
}

export default navcard;