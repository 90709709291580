import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Seasons = () => {
    return (
        <div>
            <Row className='seasons'>
            <h1 className='center oswald'>ZAPEWNIAMY CI WSPARCIE PRZEZ WSZYSTKIE PORY ROKU!</h1>
                <Col md={6} lg={6} sm={6}>
                    <Container style={{ position: "relative", textAlign: "center", color: "white" }}>
                        <img className='imgwidth' style={{ width: "100%" }} src="/images/seasons/harrowing.jpg" alt="Przykładowe zdjęcie" />
                        <div className='seasontext'>UPRAWA</div>
                    </Container>
                    <br />
                </Col>
                <Col md={6} lg={6} sm={6}>
                    <Container style={{ position: "relative", textAlign: "center", color: "white" }}>
                        <img className='imgwidth' style={{ width: "100%" }} src="/images/seasons/seeding.jpg" alt="Przykładowe zdjęcie" />
                        <div className='seasontext'>SIEW</div>
                    </Container>
                    <br />
                </Col>
                <Col md={6} lg={6} sm={6}>
                    <Container style={{ position: "relative", textAlign: "center", color: "white" }}>
                        <img className='imgwidth'style={{ width: "100%" }} src="/images/seasons/spraying2.jpg" alt="Przykładowe zdjęcie" />
                        <div className='seasontext'>OPRYSKIWANIE</div>
                    </Container>
                    <br />
                </Col>
                <Col md={6} lg={6} sm={6}>
                    <Container style={{ position: "relative", textAlign: "center", color: "white" }}>
                        <img className='imgwidth' style={{ width: "100%" }} src="/images/seasons/harvesting.jpg" alt="Przykładowe zdjęcie" />
                        <div className='seasontext'>ŻNIWA</div>
                    </Container>
                    <br />
                </Col>
            </Row>
        </div>
    );
}

export default Seasons;
