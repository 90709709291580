import React from "react";
import Topbar from "../components/topbar";
import Navbar from "../components/navbar2";
import Container from "react-bootstrap/esm/Container";
import Footer from "../components/footer";
import Navcard from "../components/navcard";
import {Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";



function Navoffer() {

    let navigate = useNavigate(); 
        const routeChange1 = () =>{ 
            let path = `nx510`; 
            navigate(path);
        }

        const routeChange2 = () =>{ 
            let path = `nx612`; 
            navigate(path);
        }

    return (
    <>
        <div>
            <Topbar />
            <Navbar />
            <section className="navoffer">
                <div className="navoffer-background" />
                <div className="navoffer-overlay" />
                <Container style={{zIndex:"2"}}>
                    <Row>
                        <h1 className="center oswald white outline">POZNAJ NASZĄ OFERTĘ NAWIGACJI!</h1>
                        <Col className="navcol">
                        <div onClick={routeChange1}>
                            <Navcard
                            title="CHCNAV NX510 SE"
                            imgsrc="../../images/CHCSet.png"/>
                        </div>  
                        </Col>
                        <Col className="navcol">
                        <div onClick={routeChange2}>
                            <Navcard 
                            title="CHCNAV NX612"
                            imgsrc="../../images/CHCnx612_3.png"/>
                        </div>
                        </Col>
                    </Row>
                <Container className="navcol">
                <   Row>
                        <Col>
                        <a href="https://chcharvester.olx.pl/home/">
                        <div className="btn orange oswald navbtn">NASZA PEŁNA OFERTA OLX</div>
                        </a>
                        </Col>
                    </Row>
                </Container> 
                </Container>
            </section>
             
            <Footer />
            </div>
    </>
        
    )
}

export default Navoffer;